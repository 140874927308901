import { Button, Divider, Table, Modal, Spin, Tooltip, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../redux/actions/CommonHttp";
import moment from "moment";
import CustomModal from "../../components/Modal/CustomModal";
import EditTraffic from "./components/EditTraffic";
import ActionMenu from "../../components/ActionMenu";
import { resetCrudState } from "../../redux/reducers/crudSlice";
import { FaRegCircleQuestion } from "react-icons/fa6";

const TrafficDataTable = () => {
  const dispatch = useDispatch();
  const trafficData = useSelector((state) => state?.crud?.trafficData?.data || []);
  const trafficPagination = useSelector(
    (state) =>
      state?.crud?.trafficData?.pagination || {
        current: 1,
        pageSize: 10,
        total: 0,
      }
  );
  const addRecordSpin = useSelector((state) => state.crud.addRecordSpin);
  const editRecordSpin = useSelector((state) => state.crud.editRecordSpin);
  const deleteRecordSpin = useSelector((state) => state.crud.deleteRecordSpin);
  const addRecordSuccess = useSelector((state) => state.crud.addRecordSuccess);
  const editRecordSuccess = useSelector((state) => state.crud.editRecordSuccess);
  const deleteRecordSuccess = useSelector((state) => state.crud.deleteRecordSuccess);

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");

  useEffect(() => {
    if (addRecordSuccess) {
      setAddModal(false);
      dispatch(resetCrudState());
    }
  }, [addRecordSuccess, dispatch]);

  useEffect(() => {
    if (editRecordSuccess) {
      setEditModal(false);
      dispatch(resetCrudState());
    }
  }, [editRecordSuccess, dispatch]);

  useEffect(() => {
    if (deleteRecordSuccess) {
      setDeleteModal(false);
      dispatch(resetCrudState());
    }
  }, [deleteRecordSuccess, dispatch]);

  useEffect(() => {
    fetchData(trafficPagination.current); // Fetch initial data for the first page
  }, [dispatch, trafficPagination.current]);

  const fetchData = (page) => {
    const params = {
      page: page,
      pageSize: trafficPagination.pageSize,
    };

    dispatch(
      DataGetAction({
        apiName: "getAllTrafficData",
        data: params,
        requestType: "fetch",
        recordName: "trafficData",
      })
    );
  };

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handleEditData = (formData) => {
    dispatch(
      DataRequestAction({
        method: "PUT",
        apiName: "updateTrafficData",
        data: formData,
        requestType: "updateRecord",
        recordName: "trafficData",
      })
    ).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        fetchData(trafficPagination.current); // Re-fetch data for the current page after editing
      }
    });
  };

  const handleDelete = () => {
    if (selectedRow) {
      dispatch(
        DataRequestAction({
          method: "DELETE",
          apiName: `deleteTrafficData`,
          data: { id: selectedRow?._id },
          requestType: "deleteRecord",
          recordName: "trafficData",
        })
      ).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          fetchData(1); // Re-fetch data from the first page after deleting
        }
      });
    }
  };

  const handleDescriptionClick = (description) => {
    setSelectedDescription(description);
    setDescriptionModal(true);
  };

  const handleConfirmDelete = (record) => {
    setSelectedRow(record);
    setDeleteModal(true);
  };

  const handleEdit = (record) => {
    setSelectedRow(record);
    setEditModal(true);
  };

  const columns2 = [
    {
      title: "ID",
      dataIndex: "trafficId",
      key: "trafficId",
      className: "table-cell font-roboto-medium",
    },

    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      className: "table-cell font-roboto-medium",
       width: "200px"
    },

    {
      title: "Coordinates (From)",
      key: "coordinates",
      className: "table-cell font-roboto-medium",
      render: (text, record) => {
        const fromLat = record.latitudeFrom;
        const fromLong = record.longitudeFrom;
        const googleMapsLink = `https://www.google.com/maps?q=${fromLat},${fromLong}`;

        return (
          <span className="flex flex-row">
            {fromLat && fromLong ? (
              <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">
                ({fromLat} - {fromLong})
              </a>
            ) : (
              "---"
            )}
          </span>
        );
      },
      width: "200px"
    },
    {
      title: "Coordinates (To)",
      key: "coordinatesTo",
      className: "table-cell font-roboto-medium",
      render: (text, record) => {
        const toLat = record.latitudeTo;
        const toLong = record.longitudeTo;
        const googleMapsLink = `https://www.google.com/maps?q=${toLat},${toLong}`;

        return (
          <span className="flex flex-row">
            {toLat && toLong ? (
              <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">
                ({toLat} - {toLong})
              </a>
            ) : (
              "---"
            )}
          </span>
        );
      },
      width: "200px"
    },
    {
      title: "Flow",
      dataIndex: "flow",
      key: "flow",
      className: "table-cell font-roboto-medium",
    },
    {
      title: "Traffic Volume",
      dataIndex: "trafficVolume",
      key: "trafficVolume",
      className: "table-cell font-roboto-medium",
    },
    {
      title: "Concentration",
      dataIndex: "concentration",
      key: "concentration",
      className: "table-cell font-roboto-medium",
      width: "100px",
       
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      className: "table-cell",
      render: (text) => (
        <Tooltip
          color="#E8BC0E"
          className="cursor-pointer"
          title={
            <div className="text-black font-roboto-regular text-[16px] cursor-pointer" style={{ maxHeight: "150px", overflowY: "auto" }}>
              {text || "---"}
            </div>
          }
        >
          {text ? text.slice(0, 40) + "..." : "---"}
        </Tooltip>
      ),
    },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      className: "table-cell font-roboto-medium",
      render: (text) => (text ? moment(text).format("DD-MM-YYYY HH:mm:ss") : "---"),
    },
    {
      title: "",
      key: "action",
      className: "table-cell",
      render: (text, record) => (
        <ActionMenu
          menuItems={[
            { label: "Edit", onClick: () => handleEdit(record) },
            { label: "Delete", onClick: () => handleConfirmDelete(record) },
          ]}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between">
        <h2 className="font-roboto-bold text-24 text-primary-text">Traffic Data</h2>
      </div>
      <Divider className="my-5" />
      <Spin spinning={deleteRecordSpin}>
        <Table
          dataSource={trafficData ? trafficData : []}
          columns={columns2}
          pagination={false} // Disable internal pagination
          scroll={{ x: 1300 }} // Enable horizontal scrolling
        />
      </Spin>
      <Pagination
        className="pt-4 flex justify-end"
        current={trafficPagination.current}
        pageSize={trafficPagination.pageSize}
        total={trafficPagination.total}
        onChange={handlePageChange}
        showSizeChanger={false}
      />
      <CustomModal title="Edit Traffic Data" modal={editModal} setModal={setEditModal}>
        <EditTraffic record={selectedRow} handleSubmit={handleEditData} setModal={setEditModal} loading={editRecordSpin} />
      </CustomModal>
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaRegCircleQuestion size={28} style={{ color: "#ff4d4f", marginRight: 10, marginTop: "10px" }} />
            <span className="text-lg font-roboto-bold">Are you sure you want to delete this information?</span>
          </div>
        }
        open={deleteModal}
        onCancel={() => setDeleteModal(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setDeleteModal(false)}
            className="cancel-button text-lg p-5"
            style={{ marginRight: "0px", fontFamily: "Roboto-Bold" }}
          >
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={handleDelete}
            className="save-button text-lg p-5"
            style={{ fontFamily: "Roboto-Bold" }}
            loading={deleteRecordSpin}
          >
            OK
          </Button>,
        ]}
      >
        <p className="ml-10 mb-5">This information will be deleted permanently.</p>
      </Modal>
    </div>
  );
};

export default TrafficDataTable;
