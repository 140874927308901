

import { Button, Divider, Form, Input, message, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import UploadComponent from "../../../components/UploadComponent/UploadComponent";

const AddBanners = ({ handleSubmit, setModal, loading }) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [stagedFiles, setStagedFiles] = useState([]);
  const [stagedDeletions, setStagedDeletions] = useState([]);
  const [uploading, setUploading] = useState(false);

  const commitChanges = async () => {
    setUploading(true); // Start the loader when committing changes

    const token = localStorage.getItem("token");
    let updatedImagesUrls = [...imageUrls];

    for (const stagedFile of stagedFiles) {
      const { name, file: actualFile } = stagedFile;

      try {
        const response = await fetch(`${process.env.REACT_APP_ROOT_URL}/generatePresignedURL`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            fileName: name,
            fileType: actualFile.type,
          }),
        });

        if (!response.ok) {
          message.error("Failed to get presigned URL");
          console.error("Failed to get presigned URL", await response.text());
          continue;
        }

        const { url } = await response.json();

        // Upload the file to S3 using the presigned URL
        try {
          const uploadResponse = await fetch(url, {
            method: "PUT",
            body: actualFile,
            headers: {
              "Content-Type": actualFile.type,
            },
          });

          if (!uploadResponse.ok) {
            message.error("Upload failed");
            console.error("Error uploading file to S3", await uploadResponse.text());
            continue;
          }

          // Trim the URL to remove query parameters
          const trimmedUrl = url.split("?")[0];
          updatedImagesUrls = [...updatedImagesUrls, trimmedUrl];
        } catch (error) {
          console.error("Error uploading file:", error);
          message.error("Upload failed");
        }
      } catch (error) {
        console.error("Error during presigned URL generation or upload:", error);
      }
    }

    setImageUrls(updatedImagesUrls);
    setStagedFiles([]);
    setStagedDeletions([]);

    setUploading(false); // Stop the loader once all changes are committed
    return updatedImagesUrls;
  };

  const onFinish = async (values) => {
    const updatedImages = await commitChanges();

    const obj = {
      ...values,
      image: updatedImages.length > 0 ? updatedImages[0] : 'https://smodprint.com/wp-content/uploads/2018/09/Large-Format-Flex-Banner-Print.jpg',
    };

    handleSubmit(obj);
    form.resetFields();
    setFiles([]);
    setImageUrls([]);
  };

  const handleCancel = () => {
    form.resetFields();
    setFiles([]);
    setImageUrls([]);
    setStagedFiles([]);
    setStagedDeletions([]);
    setModal(false);
  };

  return (
    <div>
      <Spin spinning={loading || uploading}>
        <Form form={form} name="addBanner" onFinish={onFinish} autoComplete="off" layout="vertical">
          <Form.Item
            label="Title"
            name="title"
            rules={[
              { required: true, message: "Please input the banner title!" },
            ]}
          >
            <Input size="large" maxLength={50} showCount placeholder="Enter title here..." />
          </Form.Item>

          <Form.Item
            label="Enter Link"
            name="hyperLink"
            rules={[
              { required: false, message: "Please enter a link!" },
              { pattern: /[a-zA-Z0-9-]+\./, message: "Please enter a link in the format www.example.com" },
            ]}
          >
            <Input size="large" placeholder="Valid link is https://www.example.com" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              { required: true, message: "Please input the description!" },
            ]}
          >
            <TextArea rows={4} placeholder="Enter description here..." />
          </Form.Item>

          <Form.Item
            label="Image (430 x 168)"
            name="image"
            
          >
            <UploadComponent
              fileList={files}
              setFiles={setFiles}
              setImagesUrls={setImageUrls}
              setStagedFiles={setStagedFiles}
              setStagedDeletions={setStagedDeletions}
              maxUpload={1} // Restrict upload to a single image
            />
          </Form.Item>

          <Divider style={{ marginBottom: 20 }} />

          <div className="flex justify-end">
            <Button type="primary" className="cancel-button font-roboto-bold text-lg p-5" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              className="save-button font-roboto-bold text-lg p-5"
              htmlType="submit"
              style={{ backgroundColor: "yellow", color: "black" }}
              loading={loading || uploading} // This will trigger the spinner
            >
              Save
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default AddBanners;
