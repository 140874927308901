import { Button, Divider, Form, Input, message, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import UploadComponent from "../../../components/UploadComponent/UploadComponent";

const EditBanners = ({ record, handleSubmit, setModal, loading }) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState(
    record?.image
      ? [
          {
            uid: "-1",
            name: record.image.split("/").pop(),
            status: "done",
            url: record.image,
          },
        ]
      : []
  );
  const [imageUrl, setImageUrl] = useState(record?.image || "");
  const [stagedFiles, setStagedFiles] = useState([]);
  const [stagedDeletions, setStagedDeletions] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    if (record) {
      form.setFieldsValue(record);
      setImageUrl(record?.image || "");
    }
  }, [record]);

  const commitChanges = async () => {
    try {
      setButtonLoading(true); // Start the button loader when committing changes

      const token = localStorage.getItem("token");

      let updatedImageUrl = imageUrl;

      for (const stagedFile of stagedFiles) {
        const { name, file: actualFile } = stagedFile;

        const response = await fetch(`${process.env.REACT_APP_ROOT_URL}/generatePresignedURL`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            fileName: name,
            fileType: actualFile.type,
          }),
        });

        if (!response.ok) {
          message.error("Failed to get presigned URL");
          console.error("Failed to get presigned URL", await response.text());
          continue;
        }

        const { url } = await response.json();

        // Upload the file to S3 using the presigned URL

        const uploadResponse = await fetch(url, {
          method: "PUT",
          body: actualFile,
          headers: {
            "Content-Type": actualFile.type,
          },
        });

        if (!uploadResponse.ok) {
          message.error("Upload failed");
          console.error("Error uploading file to S3", await uploadResponse.text());
          continue;
        }

        message.success("Upload successful");

        // Trim the URL to remove query parameters
        updatedImageUrl = uploadResponse.url.split("?")[0]; // Removes everything after '?'
        console.log("====updated imge url at line 89 is:", updatedImageUrl);
      }

      // Handle deletions if any
      for (const file of stagedDeletions) {
        const apiEndpoint = `${process.env.REACT_APP_ROOT_URL}/deleteFileFroms3`;
        const bodyPayload = {
          fileKey: file.name,
          bannerId: record?._id,
          imageUrl: file.url,
        };

        const response = await fetch(apiEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify(bodyPayload),
        });

        if (!response.ok) {
          const errorText = await response.text();
          message.error(`Failed to delete file: ${errorText}`);
          console.error("Error deleting file from S3 or DB", errorText);
        } else {
          message.success("File removed successfully");
        }
      }

      // Update the main state to reflect all changes
      setImageUrl(updatedImageUrl);
      setStagedFiles([]);
      setStagedDeletions([]);
      setButtonLoading(true); // Start the button loader when committing changes
      console.log("====updated imge url at line 140 is:", updatedImageUrl);
      return updatedImageUrl; // Return the updated URL for use in onFinish
    } catch (error) {
      console.log("Eoororor", error);
    }
  };

  const onFinish = async (values) => {
    setButtonLoading(true); // Start the button loading

    // Wait for all changes to complete and get the updated URL
    const updatedImage = await commitChanges();

    // Update the form data to include the latest image URL
    const obj = {
      ...values,
      image: updatedImage || "https://smodprint.com/wp-content/uploads/2018/09/Large-Format-Flex-Banner-Print.jpg",
      id: record?._id,
    };

    // Call the handleSubmit function with the updated data
    handleSubmit(obj);

    // Stop the button loading after 5 seconds
    setTimeout(() => {
      setButtonLoading(false);
    }, 5000);
  };

  const handleCancel = () => {
    setFiles(
      record?.image
        ? [
            {
              uid: "-1",
              name: record.image.split("/").pop(),
              status: "done",
              url: record.image,
            },
          ]
        : []
    );
    setImageUrl(record?.image || "");
    setModal(false);
  };

  return (
    <div>
      <Spin spinning={loading || buttonLoading}>
        <Form form={form} name="editBanner" onFinish={onFinish} autoComplete="off" layout="vertical">
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: "Please input the banner title!",
              },
            ]}
          >
            <Input size="large" maxLength={50} showCount placeholder="Enter title here..." />
          </Form.Item>

          <Form.Item
            label="Enter Link"
            name="hyperLink"
            rules={[
              {
                required: false,
                message: "Please enter a link!",
              },
              {
                pattern: /[a-zA-Z0-9-]+\./,
                message: "Please enter a link in the format www.example.com",
              },
            ]}
          >
            <Input addonBefore="https://" size="large" placeholder="www.example.com" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please input the description!",
              },
            ]}
          >
            <TextArea rows={4} placeholder="Enter description here..." />
          </Form.Item>

          <Form.Item
            label="Image (430 x 168)"
            name="image"
            className="w-[100%]"
            rules={[
              {
                required: true,
                message: "Please upload the banner image!",
              },
            ]}
          >
            <UploadComponent
              fileList={files}
              setFiles={setFiles}
              setImagesUrls={setImageUrl}
              bannerId={record?._id}
              setStagedFiles={setStagedFiles}
              setStagedDeletions={setStagedDeletions}
            />
          </Form.Item>

          <Divider style={{ marginBottom: 20 }} />

          <div className="flex justify-end">
            <Button type="primary" className="cancel-button font-roboto-bold text-lg p-5" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              className="save-button font-roboto-bold text-lg p-5"
              htmlType="submit"
              style={{ backgroundColor: "yellow", color: "black" }}
              loading={buttonLoading} // Use local loading state for the button
            >
              Save
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default EditBanners;
